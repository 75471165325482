import React from "react";
import Footer from "src/components/Footer_es";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/es/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/es/nuestros-productos/" target="_self">
                      Nuestros Productos
                    </a>
                    <ul>
                      <li>
                        <a href="/es/nuestros-productos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/tan-protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/after-sun/">
                          After Sun
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/galeria/">Galeria</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/es/nuestra-historia/" target="_self">
                      Nuestra historia
                    </a>
                  </li>
                  <li>
                    <a className="es__header" href="/es/conozca-el-sol/" target="_self">
                      Conozca el sol
                    </a>
                  </li>
                 {/*<li className="nav-selected nav-path-selected">
                    <a className="es_header" href="/es/donde-has-estado/" target="_self">
                      Dónde has estado 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Países
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/">UK</a>
                      </li>
                      <li>
                        <a href="/es/donde-has-estado/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/Por-Onde-Andaste/">PT</a>
                      </li>
                      <li>
                        <a href="/it/Ma-Dove-Sei-Stata/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/Where-Have-You-Been/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <div className="page-heritage__intro"></div>
            <section id="content">
              <div id="loading" />
              <div className="innerContent">
                <div
                  id="blockStyle4041Content127"
                  className=" ccm-block-styles"
                >
                  <div id="HTMLBlock4041" className="HTMLBlock">
                    <table
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                      width="100%"
                      className="w100pc"
                      bgcolor="#ffffff"
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              padding: "30px 20px 0px 20px"
                            }}
                          >
                            <h1
                              style={{
                                color: "#f68e27",
                                "-webkit-text-align": "center",
                                "text-align": "center",
                                "line-height": "130%"
                              }}
                            >
                              ¿QUÉ AVENTURAS REVELARÁ TU BRONCEADO ESTE VERANO?
                            </h1>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="HTMLBlock3982" className="HTMLBlock">
                  <hr
                    style={{
                      width: "250px",
                      "border-color": "#f68e27",
                      "border-style": "solid",
                      margin: "60px auto 60px auto"
                    }}
                  />
                </div>
                <div
                  id="blockStyle4080Content120"
                  className=" ccm-block-styles"
                >
                  <div id="HTMLBlock4080" className="HTMLBlock">
                    <table
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                      width="100%"
                      className="w100pc"
                      bgcolor="#ffffff"
                    >
                      <tbody>
                        <tr>
                          <td align="center" valign="top">
                            <table
                              border="0"
                              cellPadding="0"
                              cellSpacing="0"
                              width="100%"
                              className="w100pc"
                            >
                              <tbody>
                                <tr>
                                  <th
                                    align="left"
                                    valign="middle"
                                    style={{
                                      "font-weight": "normal",
                                      "vertical-align": "middle",
                                      margin: "0",
                                      padding: "0",
                                      width: "50%",
                                      "padding-left": "1.5%"
                                    }}
                                    className="block w100pc"
                                  >
                                    <table
                                      border="0"
                                      cellPadding="0"
                                      cellSpacing="0"
                                      width="100%"
                                      dir="ltr"
                                    >
                                      <tbody>
                                        <tr>
                                          <td className="side-pad">
                                            <table
                                              border="0"
                                              cellPadding="0"
                                              cellSpacing="0"
                                              width="100%"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    align="left"
                                                    style={{
                                                      "-webkit-text-transform":
                                                        "uppercase",
                                                      "text-transform":
                                                        "uppercase"
                                                    }}
                                                  >
                                                    <h3
                                                      style={{
                                                        "font-weight": "100",
                                                        margin: "0 0 25px"
                                                      }}
                                                    >
                                                      ¿Buscas un bronceado
                                                      deslumbrante? ¡Asegúrate
                                                      de estar protegida!
                                                    </h3>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td align="left">
                                                    <p>
                                                      Durante mucho tiempo ha
                                                      existido el falso mito de
                                                      que para conseguir un
                                                      bronceado bonito y de
                                                      manera rápida no es
                                                      necesario aplicarse un
                                                      protector solar. ¡Error!{" "}
                                                      <a
                                                        href="/es/conozca-el-sol/"
                                                        style={{
                                                          color: "#f68e27"
                                                        }}
                                                        target="_blank"
                                                      >
                                                        La radiación solar
                                                      </a>{" "}
                                                      en exceso directa en la
                                                      piel y sin la adecuada
                                                      protección, no solo a lo
                                                      largo del tiempo es
                                                      peligrosa para la salud,
                                                      sino también puede causar
                                                      de inmediato irritación,
                                                      enrojecimiento o quemazón.
                                                      <br />
                                                      <br />
                                                      Nuestra piel dispone de
                                                      diferentes mecanismos para
                                                      defenderse de las{" "}
                                                      <a
                                                        href="/es/conozca-el-sol/"
                                                        style={{
                                                          color: "#f68e27"
                                                        }}
                                                        target="_blank"
                                                      >
                                                        radiaciones solares
                                                      </a>
                                                      , el principal es la
                                                      producción de melanina. La
                                                      piel se defiende para
                                                      evitar posibles quemaduras
                                                      y daños en los núcleos
                                                      celulares y como
                                                      consecuencia se broncea:
                                                      es muy importante
                                                      protegerla y ayudarla.
                                                      Solo así se conseguirá un
                                                      bronceado bonito y
                                                      duradero.
                                                    </p>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="HTMLBlock3983" className="HTMLBlock">
                  <hr
                    style={{
                      width: "250px",
                      "border-color": "#f68e27",
                      "border-style": "solid",
                      margin: "60px auto 60px auto"
                    }}
                  />
                </div>
                <div
                  id="blockStyle4081Content121"
                  className=" ccm-block-styles"
                >
                  <div id="HTMLBlock4081" className="HTMLBlock">
                    <table
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                      width="100%"
                      className="w100pc"
                      bgcolor="#ffffff"
                    >
                      <tbody>
                        <tr>
                          <td align="center" valign="top">
                            <table
                              border="0"
                              cellPadding="0"
                              cellSpacing="0"
                              width="100%"
                              className="w100pc"
                              dir="rtl"
                            >
                              <tbody>
                                <tr>
                                  <th
                                    align="left"
                                    valign="middle"
                                    style={{
                                      "font-weight": "normal",
                                      "vertical-align": "middle",
                                      margin: "0",
                                      padding: "0",
                                      width: "50%",
                                      "padding-left": "1.5%"
                                    }}
                                    className="block w100pc"
                                  >
                                    <table
                                      border="0"
                                      cellPadding="0"
                                      cellSpacing="0"
                                      width="100%"
                                      dir="ltr"
                                    >
                                      <tbody>
                                        <tr>
                                          <td align="center">
                                            <img
                                              style={{
                                                "vertical-align": "text-top",
                                                "max-width": "100%",
                                                "max-height": "500px",
                                                "margin-bottom": "-70px"
                                              }}
                                              src="/assets/images/TP_30_15.jpg"
                                              alt
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </th>
                                  <th
                                    align="left"
                                    valign="middle"
                                    style={{
                                      "font-weight": "normal",
                                      "vertical-align": "middle",
                                      margin: "0",
                                      padding: "0",
                                      width: "50%",
                                      "padding-right": "1.5%"
                                    }}
                                    className="block w100pc"
                                  >
                                    <table
                                      border="0"
                                      cellPadding="0"
                                      cellSpacing="0"
                                      width="100%"
                                      dir="ltr"
                                    >
                                      <tbody>
                                        <tr>
                                          <td className="side-pad">
                                            <table
                                              border="0"
                                              cellPadding="0"
                                              cellSpacing="0"
                                              width="100%"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    align="left"
                                                    style={{
                                                      "-webkit-text-transform":
                                                        "uppercase",
                                                      "text-transform":
                                                        "uppercase"
                                                    }}
                                                  >
                                                    <h3
                                                      style={{
                                                        "font-weight": "100",
                                                        margin: "0 0 25px"
                                                      }}
                                                    >
                                                      Consigue un bronceado
                                                      intenso y duradero de
                                                      forma segura con el Nuevo
                                                      PIZ BUIN SPRAY TAN &amp;
                                                      PROTECT
                                                      <span
                                                        style={{
                                                          "font-size": "15px",
                                                          "vertical-align":
                                                            "top"
                                                        }}
                                                      >
                                                        ®
                                                      </span>
                                                    </h3>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td align="left">
                                                    <p>
                                                      Su fórmula combina una
                                                      protección UVA/UVB con
                                                      Illumitone, una tecnología
                                                      que aumenta la producción
                                                      de melanina en la piel
                                                      acelerando e
                                                      intensificando así su
                                                      bronceado natural durante
                                                      más tiempo. Además, un
                                                      innovador formato spray
                                                      permite una aplicación
                                                      multi- posición. Producto
                                                      disponible en SPF 15 y SPF
                                                      30
                                                    </p>
                                                    <p
                                                      style={{
                                                        "-webkit-text-align":
                                                          "left",
                                                        "text-align": "left",
                                                        "margin-left": "30px"
                                                      }}
                                                    >
                                                      Sus beneficios son
                                                    </p>
                                                    <ul
                                                      style={{
                                                        "-webkit-text-align":
                                                          "left",
                                                        "text-align": "left",
                                                        "list-style": "inherit",
                                                        "margin-left": "30px"
                                                      }}
                                                    >
                                                      <li
                                                        style={{
                                                          "list-style":
                                                            "inherit"
                                                        }}
                                                      >
                                                        Acelera el proceso
                                                        natural de bronceado de
                                                        la piel
                                                      </li>
                                                      <li
                                                        style={{
                                                          "list-style":
                                                            "inherit"
                                                        }}
                                                      >
                                                        Protección UVA/UVB
                                                        inmediata y eficaz
                                                      </li>
                                                      <li
                                                        style={{
                                                          "list-style":
                                                            "inherit"
                                                        }}
                                                      >
                                                        Ayuda a prevenir la
                                                        descamación y contribuye
                                                        a un bronceado más
                                                        duradero
                                                      </li>
                                                      <li
                                                        style={{
                                                          "list-style":
                                                            "inherit"
                                                        }}
                                                      >
                                                        Fácil de aplicar
                                                      </li>
                                                      <li
                                                        style={{
                                                          "list-style":
                                                            "inherit"
                                                        }}
                                                      >
                                                        Rápida absorción
                                                      </li>
                                                      <li
                                                        style={{
                                                          "list-style":
                                                            "inherit"
                                                        }}
                                                      >
                                                        No graso, ni pegajoso
                                                      </li>
                                                      <li
                                                        style={{
                                                          "list-style":
                                                            "inherit"
                                                        }}
                                                      >
                                                        Resistente al agua y al
                                                        sudor
                                                      </li>
                                                      <li
                                                        style={{
                                                          "list-style":
                                                            "inherit"
                                                        }}
                                                      >
                                                        Hidrata la piel
                                                      </li>
                                                    </ul>
                                                    <p
                                                      style={{
                                                        margin: "20px 0 0 0"
                                                      }}
                                                    >
                                                      <a
                                                        href="/es/nuestros-productos/tan-protect/"
                                                        target="_blank"
                                                        style={{
                                                          "-webkit-text-decoration":
                                                            "none",
                                                          "text-decoration":
                                                            "none"
                                                        }}
                                                      >
                                                        Descubre todo los
                                                        productos Piz Buin
                                                      </a>
                                                      <br />Y no te olvides de
                                                      hidratarte después de la
                                                      exposición al sol con la{" "}
                                                      <a
                                                        href="/es/nuestros-productos/after-sun/"
                                                        target="_blank"
                                                        style={{
                                                          color: "#f68e27"
                                                        }}
                                                      >
                                                        <br />
                                                        gama After Sun de Piz
                                                        Buin
                                                      </a>
                                                    </p>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="HTMLBlock3984" className="HTMLBlock">
                  <hr
                    style={{
                      width: "250px",
                      "border-color": "#f68e27",
                      "border-style": "solid",
                      margin: "60px auto 60px auto"
                    }}
                  />
                </div>
                <div
                  id="blockStyle4013Content122"
                  className=" ccm-block-styles"
                >
                  <div id="HTMLBlock4013" className="HTMLBlock">
                    <table
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                      width="100%"
                      className="w100pc"
                      bgcolor="#ffffff"
                    >
                      <tbody>
                        <tr>
                          <td align="center" valign="top">
                            <table
                              border="0"
                              cellPadding="0"
                              cellSpacing="0"
                              width="100%"
                              className="w100pc"
                            >
                              <tbody>
                                <tr>
                                  <th
                                    align="left"
                                    valign="middle"
                                    style={{
                                      "font-weight": "normal",
                                      "vertical-align": "middle",
                                      margin: "0",
                                      padding: "0",
                                      width: "50%",
                                      "padding-right": "1.5%",
                                      "padding-bottom": "6%"
                                    }}
                                    className="block w100pc"
                                  >
                                    <table
                                      border="0"
                                      cellPadding="0"
                                      cellSpacing="0"
                                      width="100%"
                                      dir="ltr"
                                    >
                                      <tbody>
                                        <tr>
                                          <td align="center">
                                            <img
                                              style={{
                                                "vertical-align": "text-top",
                                                "max-width": "50%"
                                              }}
                                              src="/assets/images/SUN_PICTURES_left.jpg"
                                              alt
                                              width="100%"
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinEspana"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuinespana"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UC4UKe2bJ7_abRLvHmWKSzzg"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        
          
        
      </div>
    );
  }
}

export default Page;
